<template>
    <div>
        <h1>This is test page</h1>
        <button v-on:click="warnMsg()">Warning Notification</button>
        <button v-on:click="successMsg()">Success Notification</button>
        <button v-on:click="primaryMsg()">Primary Notification</button>
        <button v-on:click="dangerMsg()">Danger Notification</button>
    </div>
</template>
<script>
export default {
  methods: {
    warnMsg: function () {
      this.$notify({
        title: 'Important message',
        text: 'Hello user! This is a notification!',
        type: 'warn '
      })
    },
    successMsg: function () {
      this.$notify({
        title: 'Important message',
        text: 'Hello user! This is a notification!',
        type: 'success '
      })
    },
    primaryMsg: function () {
      this.$notify({
        title: 'Important message',
        text: 'Hello user! This is a notification!',
        type: 'primary'
      })
    },
    dangerMsg: function () {
      this.$notify({
        title: 'Important message',
        text: 'Hello user! This is a notification!',
        type: 'error'
      })
    }
  }
}
</script>
